import React from 'react';
import BasicHeader from '../../components/mui/containers/basicHeader'

const Header=()=> {
    const header = {
        title:'Programmatic Selling Platform',
        article:'Header Bidding and OpenRTB demand partners are professionally connected to the pixfuture platform (AuctionX™) to help you level the playing field with buyers, sell more, and comply with all IAB standards.',
        picsrc:'images/publishers/publisher-header.png',
        iconsrc:'images/buyers/buyer-icon.png',
        imagesrc:'images/publishers/finalist-badge.png',
        imagelink:'https://digiday.com/announcement/awards/in-the-know-vox-media-insider-and-theskimm-are-among-this-years-digiday-media-awards-finalists/'
    }
    return (
        <BasicHeader title={header.title} article={header.article} imagesrc={header.imagesrc} imagelink={header.imagelink} button={false} divider={true} long={false} picsrc={header.picsrc} iconsrc={header.iconsrc}/>
    );
}

export default Header;